import { FC } from 'react';
import IconProps from './IconProps';

const ReferenceIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9.5H12.82C12.4 8.34 11.3 7.5 10 7.5C8.7 7.5 7.6 8.34 7.18 9.5H4C3.67 9.5 2 9.4 2 7.5V6.5C2 4.67 3.54 4.5 4 4.5H14.18C14.6 5.66 15.7 6.5 17 6.5C17.7956 6.5 18.5587 6.18393 19.1213 5.62132C19.6839 5.05871 20 4.29565 20 3.5C20 2.70435 19.6839 1.94129 19.1213 1.37868C18.5587 0.816071 17.7956 0.5 17 0.5C15.7 0.5 14.6 1.34 14.18 2.5H4C2.39 2.5 0 3.56 0 6.5V7.5C0 10.44 2.39 11.5 4 11.5H7.18C7.6 12.66 8.7 13.5 10 13.5C11.3 13.5 12.4 12.66 12.82 11.5H16C16.33 11.5 18 11.6 18 13.5V14.5C18 16.33 16.46 16.5 16 16.5H5.82C5.4 15.34 4.3 14.5 3 14.5C2.20435 14.5 1.44129 14.8161 0.87868 15.3787C0.316071 15.9413 0 16.7044 0 17.5C0 18.2956 0.316071 19.0587 0.87868 19.6213C1.44129 20.1839 2.20435 20.5 3 20.5C4.3 20.5 5.4 19.66 5.82 18.5H16C17.61 18.5 20 17.43 20 14.5V13.5C20 10.57 17.61 9.5 16 9.5ZM17 2.5C17.2652 2.5 17.5196 2.60536 17.7071 2.79289C17.8946 2.98043 18 3.23478 18 3.5C18 3.76522 17.8946 4.01957 17.7071 4.20711C17.5196 4.39464 17.2652 4.5 17 4.5C16.7348 4.5 16.4804 4.39464 16.2929 4.20711C16.1054 4.01957 16 3.76522 16 3.5C16 3.23478 16.1054 2.98043 16.2929 2.79289C16.4804 2.60536 16.7348 2.5 17 2.5ZM3 18.5C2.73478 18.5 2.48043 18.3946 2.29289 18.2071C2.10536 18.0196 2 17.7652 2 17.5C2 17.2348 2.10536 16.9804 2.29289 16.7929C2.48043 16.6054 2.73478 16.5 3 16.5C3.26522 16.5 3.51957 16.6054 3.70711 16.7929C3.89464 16.9804 4 17.2348 4 17.5C4 17.7652 3.89464 18.0196 3.70711 18.2071C3.51957 18.3946 3.26522 18.5 3 18.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ReferenceIcon;
